import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Pagination, Navigation } from 'swiper/modules';
import VideoPlayer from './VideoPlayer';

export default function VideosList({ questionVideos, questions }) {
	const videoRefs = useRef([]);
	console.log(questions, 'questions');
	console.log(questionVideos, 'questionvideos');
	const handleSlideChange = (swiper) => {
		try {
			console.log('videos');
		} catch (e) {
			console.error(e);
		}
	};

	if (!questionVideos || !questions) return null;

	return (
		<Swiper
			pagination={{ type: 'fraction' }}
			navigation={true}
			modules={[Pagination, Navigation]}
			className="mySwiper"
			style={{ width: '600px', pointerEvents: 'auto' }}
			onSlideChange={handleSlideChange}
		>
			{questions.length - 1 < questionVideos.length &&
				questions.map((skillSet) =>
					skillSet.questions?.map((question, index) => {
						const i = question.question_id;
						if (i < questionVideos.length) {
							return (
								<SwiperSlide key={question.question_id}>
									<div style={{ textAlign: 'center', height: '100px' }}>
										Q{i + 1}. {question.question_text}
									</div>
									<div className="video-container">
										<VideoPlayer
											ref={(el) => (videoRefs.current[i] = el)}
											questionVideo={questionVideos[i]?.videoUrl}
											diff={questionVideos[i]?.diff}
										/>
									</div>
								</SwiperSlide>
							);
						}
					})
				)}
			{questions.length - 1 == questionVideos.length &&
				questionVideos?.map((video, index) => (
					<SwiperSlide key={index}>
						<div style={{ textAlign: 'center', height: '100px' }}>
							Q
							{index +
								1 +
								'. ' +
								(questions[index]?.question_text ??
									questions[index]?.questions[0]?.question_text)}
						</div>
						<div className="video-container">
							<VideoPlayer questionVideo={video.videoUrl} diff={video.diff} />
						</div>
					</SwiperSlide>
				))}
		</Swiper>
	);
}
